<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="courier-order"
        canDelete
        :canSave="false"
        :loadingMsg="loadingMsg"
        navigation="courier-orders"
        :onCanDelete="item => { return item != null && (item.isApproved !== true || item.seller.isManagedByAnotherAccount) }"
        :refreshToggle="refreshToggle"
        title="Courier Order"
        :onPullSuccessAsync="pullOrder">
        <template v-slot="{ deleteItem, item }">
            <v-row no-gutters>
                <v-col v-if="item.seller != null" cols="12">
                    <BT-Field-Company
                        :id="item.sellerID"
                        :title="item.seller.companyName" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-if="item.departureLocation != null"
                        v-model="item.departureLocation"
                        label="FROM" />
                    <BT-Field-Address
                        v-else-if="item.requestedDepartureLocation != null"
                        v-model="item.requestedDepartureLocation"
                        label="FROM (Requested)" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-if="item.destinationLocation != null"
                        v-model="item.destinationLocation"
                        label="TO" />
                    <BT-Field-Address
                        v-else-if="item.requestedDestinationLocation != null"
                        v-model="item.requestedDestinationLocation"
                        label="TO (Requested)" />
                </v-col>
                
                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.courierOrderNumber"
                        label="Courier Order #" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.dueDate"
                        label="Due On" />
                </v-col>

                <v-col cols="6" sm="4">
                    <v-list-item dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Status</v-list-item-subtitle>
                            <v-list-item-title>
                                <span v-if="item.isApproved === false">Rejected</span>
                                <span v-else-if="item.fulfilledOn != null">Fulfilled: {{ item.fulfilledOn | toShortDateAndTime }}</span>
                                <span v-else-if="item.isPlanned">Route Planned</span>
                                <span v-else>Awaiting Route Plan</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col cols="6" sm="4">
                    <span v-if="item.isPickup" class="warning--text">**Customer Requesting Pickup</span>
                </v-col>

                <v-divider class="my-1" />

                <BT-Btn-Row>
                    <template v-slot:left>
                        <v-slide-y-transition group hide-on-leave>
                            <BT-Btn
                                v-if="!item.isPlanned && item.isApproved === true && item.fulfilledOn == null && item.sellerID == item.buyerID"
                                @click="planDirectRoute(item)"
                                key="1"
                                :icon="item.isPickup ? 'mdi-pickaxe' : 'mdi-map-marker-distance'"
                                :label="item.isPickup ? 'Pickup' : 'Move Directly'" />

                            <BT-Dialog
                                v-if="!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID"
                                block
                                buttonClass="my-2 primary"
                                icon="mdi-map-marker-question-outline"
                                key="2"
                                @ok="res => { createRoute(res, item) }"
                                :getOnOpenAsync="getRouteSuggestionsAsync"
                                :item="item"
                                label="Suggest Route"
                                text="Suggest Route"
                                width="550">
                                <template v-slot:activator="{ openDialog }">
                                    <BT-Btn
                                        @click="openDialog"
                                        icon="mdi-map-marker-question-outline"
                                        label="Route Suggestions" />
                                </template>
                                <template v-slot="{ item }">
                                    <v-list v-if="isLengthyArray(item.routeMovements)">
                                        <v-list-item v-for="(route, index) in item.routeMovements" :key="index" small>
                                            <v-list-item-content>
                                                <v-list-item-subtitle v-if="index == 0">
                                                    <BT-Entity
                                                        :itemValue="route.departureLocationID"
                                                        navigation="public-locations" 
                                                        single
                                                        textFilter="toFamiliarLocationLine"
                                                        useLocalCache />
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>To</v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    <BT-Entity
                                                        :itemValue="route.destinationLocationID"
                                                        navigation="public-locations" 
                                                        single
                                                        textFilter="toFamiliarLocationLine"
                                                        useLocalCache />
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-action-text>{{ route.dueArrivalOn | toDayShortDate }}</v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <div v-else>No Suggestions Found</div>
                                </template>
                            </BT-Dialog>

                            <Juncture-Builder-Btn
                                v-if="!item.isPickup && item.isApproved == true && item.fulfilledOn == null && item.sellerID == item.buyerID"
                                :courierOrder="item"
                                @saved="refreshItem"
                                key="3">
                                <template #activator="{ open }">
                                    <BT-Btn
                                        @click="open"
                                        icon="mdi-map-marker-path"
                                        label="Custom Route" />
                                </template>
                            </Juncture-Builder-Btn>

                            <BT-Blade-Button
                                v-if="item.sellerID == item.buyerID && item.fulfilledOn != null && !item.isBilled"
                                @click="invoiceCourierOrder(item)"
                                icon="mdi-receipt"
                                key="5"
                                label="Invoice Courier Order" />
                        </v-slide-y-transition>
                    </template>
                    <template v-slot:right>
                        <v-slide-y-transition group hide-on-leave>
                            <BT-Btn
                                v-if="item.isApproved == null && item.sellerID == item.buyerID"
                                @click="approveCourierOrder(item)"
                                icon="mdi-check"
                                key="1"
                                label="Approve Order" />

                            <BT-Btn
                                v-if="item.sellerID == item.buyerID && (item.isApproved == null || (item.isApproved === true && item.fulfilledOn == null))"
                                @click="rejectCourierOrder(item)"
                                buttonClass="error"
                                icon="mdi-check"
                                key="2"
                                label="Reject" />

                            <BT-Btn
                                v-if="item.isApproved !== true"
                                @click="deleteItem"
                                buttonClass="error"
                                icon="mdi-delete"
                                key="3"
                                label="Delete" />
                        </v-slide-y-transition>
                    </template>
                </BT-Btn-Row>

                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-subheader class="my-auto">Movements</v-subheader>
                        <v-btn v-if="isLengthyArray(item.junctures)" title="Clear Consignment Route" @click="() => clearConsignmentRoute(item)">
                            <v-icon class="error--text">mdi-close-octagon</v-icon>
                        </v-btn>
                    </div>
                    <BT-Table
                        :addBladeName="item.sellerID == item.buyerID ? 'courier-movement' : 'release'"
                        :canExportCSV="false"
                        :getSelectBladeData="(bData, item) => { return { id: item.courierMovementID } }"
                        hideHeader
                        hideTableHeader
                        :items="item.junctures"
                        :onCanSelect="item => { return item.courierMovementID != null }"
                        :onFilter="filterJunctures"
                        showList>
                        <template v-slot:listItem="m">
                            <BT-List-Item-Avatar v-model="m.item.buyerID" icon="mdi-account" />
                            <v-list-item-content>
                                <v-list-item-title>{{ companyAccountID() != m.item.location.companyAccountID ? m.item.location.companyAccount.companyName : m.item.location.locationName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ m.item.location | toLocationLine }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ m.item.dueOn | toShortDate }} | {{ m.item.arrivedOn != null ? 'Complete' : m.item.courierMovementID == null ? 'No Movement' : 'Pending' }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn
                                    v-if="(item.sellerID == item.buyerID || item.seller.isManagedByAnotherAccount) && m.item.courierMovementID == null && m.item.arrivedOn == null"
                                    icon
                                    small
                                    @click="createMovementFromJuncture(m.item)">
                                    <v-icon small>mdi-map-marker-plus-outline</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-Table>
                </v-col>
                
                <BT-Snack v-model="msg" />
            </v-row>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Courier-Order-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTTable: () => import('~components/BT-Table.vue'),
        JunctureBuilderBtn: () => import('~home/courier-orders/Juncture-Builder-Btn.vue')
    },
    data: function() {
        return {
            loadingMsg: null,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async refreshItem() {
            this.loadingMsg = 'Refreshing';
            await this.twiddleThumbs(3000);
            this.refreshToggle = !this.refreshToggle;
            this.loadingMsg = null;
        },
        async approveCourierOrder(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    departureLocationID: item.departureLocationID != null ? item.departureLocationID : item.requestedDepartureLocationID,
                    destinationLocationID: item.destinationLocationID != null ? item.destinationLocationID : item.requestedDestinationLocationID,
                    isApproved: true
                };

                await this.$BlitzIt.store.patch('client-orders', data);
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async clearConsignmentRoute(item) {
            console.log()
            var patchObj = {
                ...item,
                plannedJunctures: []
            }

            try {
                this.loadingMsg = 'Clearing Consignment Route';
                await this.$BlitzIt.store.patch('client-orders', patchObj);
                this.refreshToggle = !this.refreshToggle;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async createMovementFromJuncture(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var res = await this.$BlitzIt.store.post('courier-movements', { junctureID: item.id });
                item.courierMovementID = res.id;

            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async createRoute(res, item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                for (let i = 0; i < res.routeMovements.length; i++) {
                    const routeMovement = res.routeMovements[i];
                    
                    var movement = {
                        departureLocationID: routeMovement.departureLocationID,
                        destinationLocationID: routeMovement.destinationLocationID,
                        dueDepartureOn: routeMovement.dueDepartureOn,
                        dueArrivalOn: routeMovement.dueArrivalOn,
                        bundleID: routeMovement.bundleID,
                        courierOrderIDs: [item.id]
                    }

                    await this.$BlitzIt.store.post('courier-movements', movement);

                    item.isPlanned = true;
                    this.refreshToggle = !this.refreshToggle;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        filterJunctures(list) {
            var l = list;
            l.sort(firstBy(x => x.sortOrder));
            return l;
        },
        async getRouteSuggestionsAsync(item) {
            try {
                this.loadingMsg = 'Loading';

                var res = await this.$BlitzIt.api.getById('route-suggestions', item.id, null, null);
                if (res.data.data != null) {
                    res.data.data.routeMovements.sort(firstBy(x => x.sortNumber));
                }
                return res.data.data;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async invoiceCourierOrder(item) {
            try {
                var data = { id: item.id, isBillRequest: true }

                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                await this.$BlitzIt.api.patch('courier-order-fulfilling', data);
                this.$BlitzIt.store.deleteLocal('client-orders', item.id, null);
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async planDirectRoute(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var movement = {
                    departureLocation: item.departureLocation || item.requestedDepartureLocation,
                    departureLocationID: item.departureLocationID || item.requestedDepartureLocationID,
                    destinationLocation: item.destinationLocation || item.requestedDestinationLocation,
                    destinationLocationID: item.destinationLocationID || item.requestedDestinationLocationID,
                    dueDepartureOn: item.dueDate,
                    dueArrivalOn: item.dueDate,
                    courierOrderIDs: [item.id],
                    isPickup: item.isPickup
                };
                
                await this.$BlitzIt.store.post('courier-movements', movement);
                
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pullOrder(order) {
            if (order != null && this.isLengthyArray(order)) {
                order.junctures = order.junctures.orderBy('sortOrder');
            }

            return order;
        },
        async rejectCourierOrder(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    isApproved: false
                };

                await this.$BlitzIt.store.patch('client-orders', data);

                this.refreshToggle = !this.refreshToggle;
                // this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>